import { createAsyncThunk } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getLaboratorie = createAsyncThunk('laboratorie/getLaboratorie', async ({ search, limit, page, startDate, endDate, }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search
            ? { limit, page, startDate, endDate }
            : startDate && endDate
                ? { search, limit, page, startDate, endDate }
                : { search, limit, page });
        const response = await apiKey.get(`/laboratories?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createLaboratorie = createAsyncThunk('laboratorie/createLaboratorie', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/laboratories`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteLaboratorie = createAsyncThunk('laboratorie/deleteLaboratorie', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.delete(`/laboratories/${id}`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateLaboratorie = createAsyncThunk('laboratorie/updateLaboratorie', async ({ id, data }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/laboratories/${id}`, serialize({ ...data }));
        if (response.status === 200) {
            return response;
        }
        else {
            return response?.data;
        }
    }
    catch (err) {
        return ThunkApi.rejectWithValue(err);
    }
});
export const getLabById = createAsyncThunk('laboratorie/getLabById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/laboratories/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
